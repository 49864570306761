import { useState } from "react"
import { Image, Modal, NoDataFound, Spinner } from "@vesatogo/grass-core"
import { Carousel } from "@vesatogo/grass-carousel"
import FileUploadButton from "~/components/FIleUploadButton"
import toast from "react-hot-toast"
import { DownloadSimple, Trash } from "@phosphor-icons/react"

type SendConsentType = {
  isOpen: boolean
  onClose: () => void
  form: any
  disabled?: boolean
  addAttachment: (attachment: any) => void
  attachments: any[]
}

const AttachmentModal = ({
  isOpen,
  onClose,
  form,
  disabled,
  addAttachment,
  attachments,
}: SendConsentType) => {
  const [loading, setLoading] = useState(false)

  const imageSlides = attachments.map((attachment, index) => {
    return {
      slide: (
        <div
          key={index}
          className="relative w-96 h-80 mx-auto rounded border p-2"
        >
          <Image
            src={attachment.media.url}
            alt={attachment.media.url}
            className="w-full h-full object-cover rounded"
          />
          {!attachment.id ? (
            <button
              onClick={() => {
                const updatedAttachments = attachments.filter(
                  (item, i) => i !== index
                )
                form.setFieldValue("attachments", updatedAttachments)
                addAttachment(updatedAttachments)
              }}
              className="absolute top-2 right-2 bg-white text-red p-1 m-2 rounded"
            >
              <Trash size={20} />
            </button>
          ) : (
            <a
              href={attachment.media.url} target="_blank" rel="noreferrer" download="attachment"
              className="absolute top-2 right-2 bg-white text-green p-1 m-2 rounded"
            >
              <DownloadSimple size={20} />
            </a>
          )}
        </div>
      ),
    }
  })

  const handleSuccess = message => {
    const media = message.map(item => ({
      media: item.data.media,
    }))
    const updatedAttachments = [...attachments, ...media]
    form.setFieldValue("attachments", updatedAttachments)
    addAttachment(updatedAttachments)
    onClose()
  }

  return (
    <>
      <Modal
        bodyClassName="w-1/2"
        title="Attachment"
        isOpen={isOpen}
        onClose={onClose}
      >
        {attachments.length === 0 ? (
          <NoDataFound
            title={"You haven’t added an Attachment"}
            children={
              loading ? (
                <Spinner />
              ) : (
                <FileUploadButton
                  multiple
                  disabled={disabled}
                  method="put"
                  appName="setutrade"
                  environment={import.meta.env.VITE_APP_ENV}
                  endpoint={`https://${
                    import.meta.env.VITE_REST_URL
                  }/rest/trade/uploader/`}
                  media={attachments}
                  onSuccess={handleSuccess}
                  onError={message => {
                    toast.error(message)
                  }}
                />
              )
            }
          />
        ) : (
          <div>
            <div className="flex justify-end">
              {loading ? (
                <Spinner />
              ) : (
                <FileUploadButton
                  multiple
                  disabled={disabled}
                  method="put"
                  appName="setutrade"
                  environment={import.meta.env.VITE_APP_ENV}
                  endpoint={`https://${
                    import.meta.env.VITE_REST_URL
                  }/rest/trade/uploader/`}
                  media={attachments}
                  onSuccess={handleSuccess}
                  onError={message => {
                    toast.error(message)
                  }}
                />
              )}
            </div>
            <Carousel
              className="mt-5"
              showArrows={true}
              transition={false}
              showDots={false}
              slides={imageSlides}
              options={{
                speed: 4,
                loop: true,
              }}
            />
          </div>
        )}
      </Modal>
    </>
  )
}

export default AttachmentModal
