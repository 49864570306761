import { CaretDown } from "@phosphor-icons/react"
import { Button, Menu, MenuItem, Popover, Toast } from "@vesatogo/grass-core"
import toast from "react-hot-toast"
import { APIRoutes } from "~/constants/routes"
import { useInvoiceSelectorList } from "~/store/invoice-selector.store"
const InvoiceActions = () => {
  const { selected } = useInvoiceSelectorList()
  const shareViaWhatsApp = async () => {
    toast.custom(<Toast title="Message will be sent!" />)
    Object.values(selected)?.map(async invoice => {
      const id = invoice?.id
      const url = `https://${
        import.meta.env.VITE_REST_URL
      }/${APIRoutes.downloadInvoice(id)}`

      const token = localStorage.getItem("token")
      const name = invoice?.buyer?.user?.[0]?.full_name
      const total_amount = invoice?.total_amount
      const weight = invoice?.total_quantity
      const weight_unit =
        invoice?.unit?.[0]?.trade_items?.[0]?.quantity_unit?.name
      const number = invoice?.buyer?.user?.[0]?.number

      const { url: responseUrl } = await fetch(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      if (responseUrl) {
        const substring = responseUrl.split("trade/")[1]
        const body = {
          to: number,
          statusCallback: `https://${
            import.meta.env.VITE_REST_URL
          }/rest/trade/status-callback/?invoice_id=${id}`,
          contentSid: "HX0ed934b6a816ce303647e1c503410799",
          contentVariables: {
            name: name,
            weight: String(weight),
            weight_unit: String(weight_unit),
            total_amount: String(total_amount),
            link: substring.replace("/", ""),
          },
        }
        const data = await fetch(import.meta.env.VITE_WHATSAPP_URL, {
          headers: {
            "Content-Type": "application/json",
            token: import.meta.env.VITE_WHATSAPP_API_TOKEN,
          },
          method: "POST",
          body: JSON.stringify(body),
        })
      }
    })
  }
  return (
    <Popover
      minimal
      arrow={false}
      className="p-0"
      trigger="mouseenter"
      interactive
      animation="fade"
      theme="light-border"
      placement="bottom"
      content={
        <Menu className="max-h-52">
          <MenuItem onClick={shareViaWhatsApp}>Share via WhatsApp</MenuItem>
        </Menu>
      }
    >
      <Button
        className="!bg-gray-300"
        intent="none"
        leftIcon={<CaretDown weight="fill" />}
      >
        Action
      </Button>
    </Popover>
  )
}

export default InvoiceActions
