import { CheckCircle, Paperclip, Plus, Trash } from "@phosphor-icons/react"
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormGroup,
  FormInput,
  Group,
  InputGroup,
  QuerySelector,
  Tag,
  Text,
  Toast,
} from "@vesatogo/grass-core"
import { DateInput } from "@vesatogo/grass-dates"
import "@vesatogo/grass-dates/index.css"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import dayjs from "dayjs"
import {
  at,
  cloneDeep,
  debounce,
  get,
  isEmpty,
  omit,
  pick,
  set,
} from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"

import { useForm } from "@mantine/form"
import SendConsent from "~/components/SendConsent"
import Codenames from "~/constants/Codenames"
import SharedEntityGroup from "~/constants/SharedEntityGroup"

import { FormCard } from "~/components/FormCard"
import HowIsItTraded from "~/components/HowIsItTraded"
import QCObservations from "~/components/QCObservations"
import TermsAndConditions from "~/components/TermsAndConditions"
import TradeExpenses from "~/components/TradeExpenses"
import WhatIsBeingTraded from "~/components/WhatIsBeingTraded"
import { WhoIsTrading } from "~/components/WhoIsTrading"
import { TradeKinds } from "~/constants/Kinds"
import {
  Advance_Payment_Constraint,
  Advance_Payment_Update_Column,
  BookingTradeSummaryQuery,
  Credit_Note_Constraint,
  Credit_Note_Update_Column,
  Expense_Constraint,
  Expense_Update_Column,
  TradeDetailQuery,
  Trade_Attachments_Constraint,
  Trade_Attachments_Update_Column,
  Trade_Item_Constraint,
  Trade_Item_Update_Column,
  useAddUpdateSharedEntityMutation,
  useAllSiUnitsQuery,
  useAllVarietyGradesQuery,
  useAllVehiclesQuery,
  useBookingTradeSummaryQuery,
  useDeleteExpenseItemMutation,
  // useDeleteTradeItemMutation,
  useGetSharedEntityQuery,
  useIncrementTradeCounterMutation,
  useTradeDetailQuery,
  useUpsertTradeMutation,
  useUserPackingQuery,
} from "~/generated/graphql"
import { getBookingQuantity } from "~/utils/bookingPavtiUtils"
import { expenseAmount, normalizeExpenses } from "~/utils/expenseAmount"
import { useDepartment } from "../../../utils/useDepartment"
import { SideBar } from "./sections/SideBar"
import { PackingMaterialSaveModal } from "~/components/PackingMaterial/PackingMaterialSaveModal"
import AttachmentModal from "~/components/AttachmentModal"
import { AppRoutes } from "~/constants/routes"

type SharedEntity = {
  group: SharedEntityGroup
}

const TradeRequestDetail = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [attachmentModal, setAttachmentModal] = useState(false)
  const [, upsertSharedEntity] = useAddUpdateSharedEntityMutation()
  const [{ fetching, data: uData }, upsertTrade] = useUpsertTradeMutation()
  // const [, deleteTradeItem] = useDeleteTradeItemMutation()
  const [, deleteExpenseItem] = useDeleteExpenseItemMutation()
  const [, incrementTradeCounter] = useIncrementTradeCounterMutation()
  const { id: sharedDeptId } = useDepartment()
  const [{ data: sData }] = useGetSharedEntityQuery({
    variables: {
      group: SharedEntityGroup.KiteCurrency,
      codename: Codenames.KiteDefaultCurrency,
    },
  })
  const [packingOpen, setPackingOpen] = useState(false)

  const defaultCurrency = sData?.setu_trade_shared_entity?.[0]
  const { id } = useParams()
  const form = useForm<any>({
    initialValues: {
      mode: "direct",
      kind: "pro_forma",
      attachments: [] as any,
      packaging: "raw_material",
      trade_items: [{ rate: 0 }],
      start_date: new Date(),
      end_date: null,
      expiry: null,
      expenses: [{}],
      advance_payment: [],
      credit_notes: [],
      tracking_code: crypto.randomUUID(),
    },
  })

  const qcForm = useForm<any>({
    initialValues: {
      results: [],
    },
  })
  const { values: state, setValues: setState } = form
  const [isOpen, setOpen] = useState(false)
  const idNum = parseInt(id as any)

  const [removedTradeItems, setRemovedTradeItems] = useState<
    Record<string, any>
  >({})
  const [removedExpenseItems, setRemovedExpenseItems] = useState<
    Record<string, any>
  >({})
  const [{ data: dData }] = useTradeDetailQuery({
    variables: { id: idNum },
    pause: !idNum,
    requestPolicy: "network-only",
  })

  const [{ data: pData }] = useUserPackingQuery({
    variables: {
      where: {
        user_id: {
          _eq: state?.seller?.shared_id,
        },
      },
    },
  })

  // Disable the form is the trade has a receipt associated with it because it will create mismatch in receipt calculations.
  const hasReceipt = dData?.trade_by_pk?.receipt_id
  const hasInvoice = dData?.trade_by_pk?.invoice !== null

  function updateTradeState(
    trade?: TradeDetailQuery["trade_by_pk"],
    bookingGrades?: BookingTradeSummaryQuery
  ) {
    if (!trade) return

    const newState = {
      ...trade,
      attachments: trade.attachments?.map(attachment => attachment),
      parent_trade:
        ((dData?.trade_by_pk?.kind === TradeKinds.InwardPatti ||
          dData?.trade_by_pk?.kind === TradeKinds.StoragePatti ||
          dData?.trade_by_pk?.kind === TradeKinds.Booking) &&
          newHishobPatti) ||
        newBookingPatti
          ? pick(dData?.trade_by_pk, "id", "kind", "start_date")
          : // : dData?.trade_by_pk?.kind === TradeKinds.Booking && newHishobPatti
            // ? null
            trade?.parent_trade,
      kind: newHishobPatti
        ? TradeKinds.HishobPatti
        : newInwardPatti
        ? TradeKinds.InwardPatti
        : newBookingPatti
        ? TradeKinds.Booking
        : trade.kind,
      invoice: trade.invoice,
      buyer: { ...trade.buyer?.user[0], shared_id: trade.buyer?.id },
      commodity: {
        ...trade.commodity?.commodity[0],
        shared_id: trade.commodity?.id,
      },
      commodity_variety: {
        ...trade.commodity_variety?.commodity_variety[0],
        shared_id: trade.commodity_variety?.id,
      },
      vehicle: {
        ...trade.vehicle,
        shared_id: trade?.vehicle?.id,
      },
      plot_crop: {
        ...trade.plot_crop?.plot_crop[0],
        shared_id: trade.plot_crop?.id,
      },
      facilitator: {
        ...trade.facilitator?.facilitator[0],
        shared_id: trade.facilitator?.id,
      },
      seller: {
        ...trade?.seller?.user[0],
        shared_id: trade?.seller?.id,
      },
      start_date: trade.start_date && new Date(trade.start_date),
      end_date: trade.end_date && new Date(trade.end_date),
      expiry: trade.expiry && new Date(trade.expiry),
      quantity_unit: {
        ...trade.trade_items[0]?.quantity_unit?.si_unit?.[0],
        shared_id: trade.trade_items[0]?.quantity_unit?.id,
      },
      trade_items: trade.trade_items?.length
        ? trade.trade_items.map(item => ({
            ...item,
            quantity:
              newBookingPatti || newInwardPatti || newHishobPatti
                ? getBookingQuantity(bookingGrades, item.variety_grade?.id)
                : item.quantity,
            variety_grade: {
              ...item.variety_grade?.variety_grade[0],
              shared_id: item.variety_grade?.id,
            },
          }))
        : [{}],
      expenses: normalizeExpenses(trade.expenses),
      advance_payment: trade.advance_payments?.map(advance => ({
        ...advance,
        bourn_by: { id: advance.bourn_by, name: advance.bourn_by },
      })),
      credit_notes: trade?.credit_notes,
      amount_deducted: (trade.total_amount * trade.standard_deduction) / 100,
      quantity_deducted:
        (trade.total_quantity * trade.standard_deduction) / 100,
    }

    setState(newState)
    runComputations(newState)

    // Set QC Values intially
    // Ideally Every Trade Item should map to a single Result
    // 3 Items = 3 Results the mapping happens via the Grade inside the template
    qcForm.setFieldValue(
      "results",
      trade.trade_items
        ?.filter(item => item.result?.result?.[0])
        ?.map(item => {
          const result = item.result?.result?.[0]
          return {
            ...result,
            observations: result?.observations.map((obs, index) => ({
              ...omit(obs, ["sample_observation", "template_parameter"]),
              sample_observation_id: obs.sample_observation?.id,
              observation_number: obs.sample_observation?.observation_number,
              quality_parameter_id:
                obs.template_parameter?.quality_parameter_id,
              attachments: obs.sample_observation?.attachments?.map(
                attachment => attachment.media
              ),
              index,
            })),
            shared_id: item.result?.id,
          }
        })
    )
  }
  const tradeId = dData?.trade_by_pk?.id
  const isBookingTrade = state?.kind === TradeKinds.Booking
  // We use this directly becuase we dont want mutations of the state and we need the
  // last saved value
  const tradeOldKind = dData?.trade_by_pk?.kind

  // Is the trade confirmed by the admin
  const isTradeConfirmed = state?.status === "Verified"
  const newHishobPatti = Boolean(searchParams.get("new"))
  const newInwardPatti = Boolean(searchParams.get("newInward"))
  const newBookingPatti = Boolean(searchParams.get("newBooking"))

  // Is the parent trade a Booking Patti
  const isParentBookingTrade =
    state?.parent_trade?.kind === TradeKinds.Booking ||
    (tradeOldKind === TradeKinds.Booking && newHishobPatti)

  // Is the Parent Trade a Hishob Patti
  const isParentSaudaPavti =
    state?.parent_trade?.kind === TradeKinds.SaudaPavti ||
    (tradeOldKind === TradeKinds.SaudaPavti && newHishobPatti)

  // Parent Trade ID
  const parentTradeId = state?.parent_trade?.id

  const isHishobPatti = isTradeConfirmed && newHishobPatti
  const isInwardPatti = newInwardPatti

  const isInheritedHishobPatti =
    (state?.parent_trade || newHishobPatti) &&
    state?.kind === TradeKinds.HishobPatti

  const isNewStorageHishobPatti =
    state?.parent_trade?.kind === TradeKinds.StoragePatti && newHishobPatti

  // const isDisabled = hasReceipt ? true : isTradeConfirmed && !isHishobPatti

  // Disabled Conditions
  const isCompletelyDisabled =
    hasReceipt ||
    (isTradeConfirmed && !newHishobPatti && !newInwardPatti && !newBookingPatti)
  const isStatusGenerateSale = state?.status === Codenames.GenerateSales
  const isStatusGenerateInward = state?.status === Codenames.GenerateInward
  const bookingSummaryId =
    state?.kind === TradeKinds.InwardPatti && newHishobPatti
      ? tradeId
      : parentTradeId
      ? parentTradeId
      : dData?.trade_by_pk?.trades?.length ||
        newHishobPatti ||
        newInwardPatti ||
        newBookingPatti
      ? tradeId
      : undefined
  const [{ data: bookingData }] = useBookingTradeSummaryQuery({
    variables: {
      id: bookingSummaryId,
    },
    pause: !bookingSummaryId,
    requestPolicy: "network-only",
  })

  const moreTrades = bookingSummaryId
    ? bookingData?.trade_grade_quantity_summary_report?.more_trade
    : true

  useEffect(() => {
    if (tradeId) {
      setRemovedTradeItems({})
      updateTradeState(dData?.trade_by_pk, bookingData)
    }
  }, [tradeId, bookingSummaryId, bookingData])

  useEffect(() => {
    if (uData?.insert_trade_one) {
      setRemovedTradeItems({})
      setRemovedExpenseItems({})
      updateTradeState(uData?.insert_trade_one)
    }
  }, [uData])

  useEffect(() => {
    if (
      dData &&
      sharedDeptId &&
      dData?.trade_by_pk?.department.id !== sharedDeptId
    ) {
      navigate("/d/trade-requests")
    }
  }, [sharedDeptId, dData?.trade_by_pk?.department.reference_id])
  const runComputations = useMemo(
    () =>
      debounce(values => {
        const stateCopy = cloneDeep(values)
        const trade = stateCopy
        let tradeTotalQty = 0,
          tradeTotalAmount = 0,
          totalExpenseAmount = 0,
          advanceTotalAmount = 0
        for (let j = 0; j < trade.trade_items.length; j++) {
          let itemTotalAmount = 0
          const item = trade.trade_items[j]
          const quantity = Number(item.quantity) || 0
          const rate = Number(item.rate) || 0
          itemTotalAmount += quantity * rate
          stateCopy.trade_items[j].total_amount = itemTotalAmount
          tradeTotalAmount += itemTotalAmount
          tradeTotalQty += quantity
        }
        stateCopy.total_quantity = tradeTotalQty
        stateCopy.total_amount = tradeTotalAmount
        // receiptTotalQty += tradeTotalQty
        // receiptTotalAmount += tradeTotalAmount

        stateCopy.amount_deducted =
          stateCopy.total_amount *
          (Number(stateCopy.standard_deduction || 0) / 100)

        stateCopy.quantity_deducted =
          stateCopy.total_quantity *
          (Number(stateCopy.standard_deduction || 0) / 100)

        for (let j = 0; j < stateCopy.expenses.length; j++) {
          const expense = stateCopy.expenses[j]
          const expenseParams = {
            kind: expense?.kind?.id,
            buyerAmount: Number(expense.buyer?.value) || 0,
            sellerAmount: Number(expense.seller?.value) || 0,
            facilitatorAmount: Number(expense.facilitator?.value) || 0,
            finalAmount: stateCopy.total_amount,
            finalQuantity:
              stateCopy.total_quantity - stateCopy.quantity_deducted,
            totalQuantity: stateCopy.total_quantity,
          }
          expense.amount = expenseAmount(expenseParams)
          totalExpenseAmount += expense.amount
        }

        for (let j = 0; j < stateCopy.advance_payment.length; j++) {
          const advance = stateCopy.advance_payment[j]
          advanceTotalAmount += Number(advance.amount) || 0
        }
        stateCopy.advance_amount = advanceTotalAmount
        stateCopy.expense_amount = totalExpenseAmount
        form.setValues(stateCopy)
      }, 500),
    []
  )

  function inputProps(name: string, sharedEntity?: SharedEntity) {
    return {
      value: get(state, name, ""),
      onChange: async (e: any) => {
        const stateCopy = cloneDeep(state)
        let facilitator = stateCopy.seller?.companies?.[0]?.company
        if (name === "mode" && e === "facilitator" && facilitator) {
          const { data: facilitatorShared } = await upsertSharedEntity({
            reference_id: facilitator.codename,
            group_name: SharedEntityGroup.KiteCompany,
            name: facilitator.name,
          })
          let shared_id =
            facilitatorShared?.insert_setu_trade_shared_entity_one?.id
          set(stateCopy, "facilitator", { ...facilitator, shared_id })
        } else if (
          (name === "mode" && e === "direct") ||
          (name === "mode" && e === "facilitator" && !facilitator) ||
          (name === "seller" && !e?.companies.length)
        ) {
          set(stateCopy, "facilitator", null)
          set(stateCopy, "mode", "direct")
        }

        if (sharedEntity && e?.codename && e?.name && !e?.shared_id) {
          const { data } = await upsertSharedEntity({
            reference_id: e.codename,
            group_name: sharedEntity.group,
            name: e.name,
          })
          set(stateCopy, name, {
            ...e,
            shared_id: data?.insert_setu_trade_shared_entity_one?.id,
          })
        } else {
          set(stateCopy, name, e?.target ? e.target.value : e)
        }
        runComputations(stateCopy)
        setState(stateCopy)
      },
    }
  }
  const finalTotal =
    (form.values.total_amount || 0) - (form.values.amount_deducted || 0)

  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Trade Requests",
              link: "/d/trade-requests",
              isActive: false,
            },
            {
              text: id?.toString() || "New",
              link: `/d/trade-requests/${id}`,
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
        <Link to={`?timeline=${id}`}>Timeline</Link>
      </header>
      <Group className="flex gap-4 px-5 pt-8 pb-16 relative">
        <SideBar
          key={state?.id || `trades_${state?.trades?.length}`}
          id={bookingSummaryId}
          disableNew={!moreTrades}
          kind={state?.parent_trade?.kind || dData?.trade_by_pk?.kind}
        />
        <div className="w-[70%] mx-auto">
          <WhoIsTrading
            form={form}
            inputProps={inputProps}
            showBuyer={state?.kind !== TradeKinds.StoragePatti}
            isDisabled={
              (isCompletelyDisabled || isInheritedHishobPatti) &&
              !isNewStorageHishobPatti
            }
            disableBuyer={
              isStatusGenerateInward &&
              state?.kind === "final" &&
              state?.parent_trade?.kind === "inward"
                ? false
                : isNewStorageHishobPatti
                ? false
                : isStatusGenerateSale ||
                  isCompletelyDisabled ||
                  (isInheritedHishobPatti && !isParentBookingTrade)
            }
          />
          <HowIsItTraded
            form={form}
            inputProps={inputProps}
            showBookingKinds
            showInwardKinds={newInwardPatti}
            isDisabled={
              isStatusGenerateSale ||
              isCompletelyDisabled ||
              isInheritedHishobPatti
            }
          />
          <WhatIsBeingTraded
            form={form}
            inputProps={inputProps}
            isDisabled={
              isStatusGenerateSale ||
              isCompletelyDisabled ||
              isInheritedHishobPatti
            }
          />
          <FormCard title="When is it traded">
            <div className={"grid grid-cols-4 gap-4"}>
              <FormGroup required label="Start Date of Trade">
                <DateInput
                  {...inputProps("start_date")}
                  disabled={
                    isStatusGenerateSale ||
                    (isCompletelyDisabled && !isHishobPatti)
                  }
                  popoverPanelClassName={classNames(
                    isCompletelyDisabled ? "hidden" : ""
                  )}
                />
              </FormGroup>
              <FormGroup label="End Date of Trade">
                <DateInput
                  {...inputProps("end_date")}
                  disabled={
                    isStatusGenerateSale ||
                    (isCompletelyDisabled && !isHishobPatti)
                  }
                  popoverPanelClassName={classNames(
                    isCompletelyDisabled ? "hidden" : ""
                  )}
                />
              </FormGroup>
              {isHishobPatti ? null : (
                <FormGroup label="Validity of Trade" required>
                  <DateInput
                    {...inputProps("expiry")}
                    disabled={
                      isStatusGenerateSale ||
                      (isCompletelyDisabled && !isHishobPatti)
                    }
                    popoverPanelClassName={classNames(
                      isCompletelyDisabled ? "hidden" : ""
                    )}
                  />
                </FormGroup>
              )}
              <FormGroup label="Vehicle">
                <QuerySelector
                  dataHook={useAllVehiclesQuery}
                  serverSideQuery
                  useWildCardsInQuery={false}
                  variables={{
                    first: 20,
                  }}
                  getOptionLabel={item => item.codename}
                  {...inputProps("vehicle", {
                    group: SharedEntityGroup.StarlingVehicle,
                  })}
                  disabled={
                    isStatusGenerateSale ||
                    (isCompletelyDisabled && !isHishobPatti)
                  }
                />
              </FormGroup>
            </div>
          </FormCard>
          {isBookingTrade ? null : (
            <QCObservations form={qcForm} commodity={form.values.commodity} />
          )}
          <FormCard title="Trade Particulars">
            <div className="mb-3 border rounded-lg">
              <header className="flex justify-between items-center p-2">
                <h6>Add Multiple Grades</h6>

                <div className="flex gap-2">
                  <Button
                    onClick={() => {
                      setAttachmentModal(true)
                    }}
                    variant="ghost"
                    size="sm"
                    intent="info"
                    className="flex items-center gap-1"
                    leftIcon={<Paperclip size={15} />}
                  >
                    {form.values.attachments?.length || 0}
                  </Button>
                  {isCompletelyDisabled ||
                  isBookingTrade ||
                  isParentBookingTrade ? null : (
                    <Button
                      onClick={() => {
                        setState(prev => ({
                          ...prev,
                          trade_items: prev.trade_items?.length
                            ? [
                                {
                                  quantity: 0,
                                  variety_grade: null,
                                  rate: 0,
                                  rate_unit: prev.trade_items?.[0]?.rate_unit,
                                  quantity_unit:
                                    prev.trade_items?.[0]?.quantity_unit,
                                },
                                ...prev.trade_items,
                              ]
                            : [{}],
                        }))
                      }}
                      size="sm"
                      leftIcon={<Plus />}
                    >
                      Grade
                    </Button>
                  )}
                </div>
              </header>
              <hr />
              <div className="p-4 border-b">
                {state?.trade_items?.map((item, index) => {
                  let itemRemoved = removedTradeItems[item.id] ? true : false

                  let remainingQuantity = getBookingQuantity(
                    bookingData,
                    item?.variety_grade?.shared_id
                  )

                  const qcResult = qcForm.values.results.find(
                    result =>
                      result.template.grade.name === item.variety_grade?.name
                  )

                  const baseRateKey = qcResult ? "daily_rate" : "rate"
                  const dailyRate = Number(item.daily_rate) || 0
                  const evalutation = qcResult?.evaluations?.[0]
                  const recommendedDeducatedRate =
                    dailyRate - dailyRate * ((evalutation?.value || 0) / 100)

                  return (
                    <div
                      key={index}
                      className="grid grid-cols-4 gap-4 mb-4 last:mb-0"
                    >
                      <FormGroup label="Grade" className="flex-1">
                        <QuerySelector
                          dataHook={useAllVarietyGradesQuery}
                          variables={{ variety: state?.commodity_variety?.id }}
                          {...inputProps(`trade_items.${index}.variety_grade`, {
                            group: SharedEntityGroup.KiteGrade,
                          })}
                          isDisabled={
                            isStatusGenerateSale ||
                            (isCompletelyDisabled && !isHishobPatti) ||
                            itemRemoved
                          }
                          isOptionDisabled={(opt: any) =>
                            state?.trade_items?.find(
                              item => item.variety_grade?.id === opt.id
                            )
                          }
                        />
                      </FormGroup>
                      <div className="flex flex-col gap-3 w-full">
                        <FormGroup label="Rate" className="flex-1">
                          <InputGroup
                            inputProps={{
                              ...inputProps(
                                `trade_items.${index}.${baseRateKey}`
                              ),
                              onChange(e) {
                                if (baseRateKey === "daily_rate") {
                                  const daily_rate = Number(e.target.value) || 0
                                  const newRate =
                                    daily_rate -
                                    daily_rate *
                                      ((evalutation?.value || 0) / 100)
                                  form.setFieldValue(
                                    `trade_items.${index}.rate`,
                                    newRate
                                  )
                                  form.setFieldValue(
                                    `trade_items.${index}.daily_rate`,
                                    e.target.value
                                  )
                                  form.setFieldValue(
                                    `trade_items.${index}.total_amount`,
                                    newRate * (item.quantity || 0)
                                  )
                                } else {
                                  form.setFieldValue(
                                    `trade_items.${index}.rate`,
                                    e.target.value
                                  )
                                }
                              },
                              name: `trade_items.${index}`,
                              type: "number",
                            }}
                            disabled={
                              isStatusGenerateSale ||
                              (isCompletelyDisabled && !isHishobPatti) ||
                              itemRemoved
                            }
                            className="self-end w-full"
                            leftElement={
                              <Tag className="ml-1" variant="minimal">
                                {defaultCurrency?.name || "INR"}
                              </Tag>
                            }
                          />
                        </FormGroup>
                        {qcResult && (
                          <FormGroup
                            label="QC Deducted Rate"
                            className="flex-1"
                            helperText={`Recommended QC Deducted Rate : ${inr(
                              recommendedDeducatedRate
                            )}`}
                          >
                            <InputGroup
                              inputProps={{
                                ...inputProps(`trade_items.${index}.rate`),
                                type: "number",
                              }}
                              disabled={
                                isStatusGenerateSale ||
                                (isCompletelyDisabled && !isHishobPatti) ||
                                itemRemoved
                              }
                              className="self-end w-full"
                              leftElement={
                                <Tag variant="minimal">
                                  {defaultCurrency?.name || "INR"}
                                </Tag>
                              }
                            />
                          </FormGroup>
                        )}
                      </div>
                      <FormGroup
                        label={
                          bookingSummaryId
                            ? `Quantity (Max: ${remainingQuantity})`
                            : "Quantity"
                        }
                        className="flex-1"
                      >
                        <InputGroup
                          className="vt-weight-listner"
                          inputProps={{
                            ...inputProps(`trade_items.${index}.quantity`),
                            id: "vt_weight_listener",
                            type: "number",
                            className: "mr-24",
                            onChange: e => {
                              let value = Number(e.target.value)
                              let original = Number(remainingQuantity)
                              let result =
                                bookingSummaryId && value > original
                                  ? original
                                  : e.target.value
                              inputProps(
                                `trade_items.${index}.quantity`
                              ).onChange(result)
                            },
                          }}
                          disabled={
                            isStatusGenerateSale ||
                            (isCompletelyDisabled && !isHishobPatti) ||
                            itemRemoved
                          }
                          rightElement={
                            <QuerySelector
                              components={{
                                IndicatorSeparator: () => null,
                                ClearIndicator: () => null,
                              }}
                              getControlStyles={prev => {
                                return {
                                  ...prev,
                                  border: "none",
                                }
                              }}
                              dataHook={useAllSiUnitsQuery}
                              variables={{ type: ["Weight", "SKU"] }}
                              isDisabled={
                                isStatusGenerateSale ||
                                (isCompletelyDisabled && !isHishobPatti) ||
                                itemRemoved ||
                                index > 0
                              }
                              {...inputProps(`quantity_unit`, {
                                group: SharedEntityGroup.KiteSiUnit,
                              })}
                            />
                          }
                        />
                      </FormGroup>
                      <div className="flex gap-4">
                        <div className="flex flex-col gap-3">
                          <FormInput
                            label="Total Amount"
                            className="flex-[0.75] text-right"
                            value={inr(
                              (item.quantity || 0) * (item[baseRateKey] || 0)
                            )}
                            disabled
                          />
                          {qcResult && (
                            <FormInput
                              label="Amount after QC deduc."
                              className="flex-[0.75] text-right"
                              value={inr(item.total_amount)}
                              disabled
                              helperText={`Amount after system QC deduc. : ${inr(
                                recommendedDeducatedRate * (item.quantity || 0)
                              )}`}
                            />
                          )}
                        </div>
                        <div className="flex self-start justify-center">
                          {isBookingTrade ||
                          isStatusGenerateSale ||
                          isParentBookingTrade ? null : !isTradeConfirmed ? (
                            <button
                              className="text-red-500 pt-5"
                              onClick={async () => {
                                if (item.id) {
                                  return
                                  // await deleteTradeItem({ id: item.id })
                                }

                                const stateCopy = cloneDeep(state)
                                stateCopy.trade_items.splice(index, 1)
                                stateCopy.trade_items = stateCopy.trade_items
                                  ?.length
                                  ? stateCopy.trade_items
                                  : [{}]
                                setState(stateCopy)
                                runComputations(stateCopy)
                              }}
                            >
                              <Trash height={24} width={24} />
                            </button>
                          ) : isHishobPatti ? (
                            <Checkbox
                              key={itemRemoved.toString()}
                              checked={itemRemoved}
                              onChange={() => {
                                setRemovedTradeItems(prev => {
                                  if (itemRemoved) {
                                    delete prev[item.id]
                                  } else {
                                    prev[item.id] = true
                                  }
                                  return prev
                                })
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div>
                <Group className="flex items-center gap-4 p-3 border-b">
                  <Text className="flex-[2_1_0%]">Total</Text>
                  <Text className="flex-1 text-right">
                    {state.total_quantity} {state?.quantity_unit?.name || "na"}
                  </Text>
                  <Text className="flex-[0.75] text-right">
                    {inr(state.total_amount)}
                  </Text>
                  <span className="w-10" />
                </Group>
                <Group className="flex items-center gap-4 p-3 border-b">
                  <Group className="flex gap-2 items-center flex-1">
                    <Text>Standard Deduction</Text>
                  </Group>
                  <Group className="flex gap-2 items-center flex-1">
                    <InputGroup
                      inputProps={{
                        ...inputProps("standard_deduction"),
                        max: 100,
                        min: 0,
                        type: "number",
                        placeholder: "Value in %",
                        className: "text-right",
                      }}
                      className="flex-1"
                      disabled={isStatusGenerateSale || isCompletelyDisabled}
                      rightElement={<span className="mr-2">%</span>}
                    />
                  </Group>
                  <Text className="flex-1 text-right">
                    {state.quantity_deducted?.toFixed?.(2)}{" "}
                    {state?.quantity_unit?.name || "na"}
                  </Text>
                  <Text className="flex-[0.75] text-right">
                    {inr(state.amount_deducted)}
                  </Text>
                  <span className="w-10" />
                </Group>
                <Group className="flex items-center gap-4 p-3 bg-gray-200">
                  <Text className="flex-[2_1_0%]">Final</Text>
                  <Text className="flex-1 text-right">
                    {(
                      (form.values.total_quantity || 0) -
                      (form.values.quantity_deducted || 0)
                    )?.toFixed(2)}{" "}
                    {state?.quantity_unit?.name || "na"}
                  </Text>
                  <Text className="flex-[0.75] text-right">
                    {inr(finalTotal)}
                  </Text>
                  <span className="w-10" />
                </Group>
              </div>
            </div>
          </FormCard>
          <TradeExpenses
            form={form}
            inputProps={inputProps}
            isDisabled={isCompletelyDisabled}
            sharedDeptId={sharedDeptId}
            isStatusGenerateSale={isStatusGenerateSale}
            hasInvoice={hasInvoice}
            onExpenseTemplateChange={expenseConfig => {
              const stateCopy = cloneDeep(form.values)
              for (const expense of stateCopy.expenses) {
                setRemovedExpenseItems(prev => {
                  if (expense?.buyer?.id) {
                    prev[expense.buyer.id] = true
                  }
                  if (expense?.seller?.id) {
                    prev[expense.seller.id] = true
                  }
                  return prev
                })
              }
              stateCopy.expenses = normalizeExpenses(
                expenseConfig.expense_configs || [],
                true
              )
              runComputations(stateCopy)
              form.setValues(stateCopy)
            }}
            onDeleteExpense={(idx, expense) => {
              const stateCopy = cloneDeep(form.values)
              stateCopy.expenses.splice(idx, 1)

              setRemovedExpenseItems(prev => {
                if (expense?.buyer?.id) {
                  prev[expense.buyer.id] = true
                }
                if (expense?.seller?.id) {
                  prev[expense.seller.id] = true
                }
                return prev
              })
              form.setValues(stateCopy)
              runComputations(stateCopy)
            }}
            onDeleteAdvancePayment={idx => {
              const stateCopy = cloneDeep(form.values)
              stateCopy.advance_payment.splice(idx, 1)
              form.setValues(stateCopy)
              runComputations(stateCopy)
            }}
          />
          <TermsAndConditions
            isDisabled={isStatusGenerateSale || isCompletelyDisabled}
            kind={form.values.kind}
            mode={form.values.mode}
            sharedDeptId={sharedDeptId}
            seller_tnc={form.values.seller_tnc}
            buyer_tnc={form.values.buyer_tnc}
            onChange={(path, value) => form.setFieldValue(path, value)}
          />
        </div>
      </Group>
      <footer className="fixed bg-white left-0 bottom-0 w-full p-2 shadow-sm flex justify-end gap-4">
        {moreTrades &&
        isCompletelyDisabled &&
        state.kind !== "final" &&
        !newInwardPatti &&
        !hasReceipt ? (
          <Button
            onClick={() => setSearchParams({ new: "true" })}
            className="!bg-cyan-400"
            leftIcon={<CheckCircle />}
          >
            Generate Hishob Patti
          </Button>
        ) : null}
        {isCompletelyDisabled &&
        !(isStatusGenerateSale && !hasInvoice && !newInwardPatti) ? null : (
          <Button
            loading={fetching}
            onClick={async () => {
              const std = Number(state?.standard_deduction)
              if (!isEmpty(removedExpenseItems))
                await deleteExpenseItem({
                  ids: Object.keys(removedExpenseItems),
                })
              const quantityUnit = state?.quantity_unit
              const { data: iData, error } = await upsertTrade({
                input: {
                  id:
                    id === "new" ||
                    id === "newInward" ||
                    isHishobPatti ||
                    (newHishobPatti && isStatusGenerateInward) ||
                    isInwardPatti ||
                    newBookingPatti
                      ? undefined
                      : state?.id,
                  tracking_code:
                    id === "new" ||
                    id === "newInward" ||
                    isHishobPatti ||
                    (newHishobPatti && isStatusGenerateInward) ||
                    isInwardPatti ||
                    newBookingPatti
                      ? undefined
                      : state?.tracking_code,
                  parent_trade_id:
                    isHishobPatti ||
                    (newHishobPatti && isStatusGenerateInward) ||
                    isInwardPatti ||
                    newBookingPatti
                      ? state?.id
                      : null,
                  packaging: state?.packaging,
                  kind: isHishobPatti ? TradeKinds.HishobPatti : state?.kind,
                  mode: state?.mode,
                  standard_deduction: std ?? null,
                  buyer_tnc: state?.buyer_tnc,
                  seller_tnc: state?.seller_tnc,
                  vehicle_id: state?.vehicle?.shared_id,
                  current_status_id: "Draft",
                  attachments: {
                    data: state?.attachments?.map(attachment => ({
                      media_id: attachment.media.id,
                    })),
                    on_conflict: {
                      constraint:
                        Trade_Attachments_Constraint.TradeAttachmentsTradeIdMediaId_39bcf3c3Uniq,
                      update_columns: [],
                    },
                  },
                  start_date:
                    state?.start_date &&
                    dayjs(state?.start_date).format("YYYY-MM-DD"),
                  end_date:
                    state?.end_date &&
                    dayjs(state?.end_date).format("YYYY-MM-DD"),
                  expiry:
                    state?.expiry && dayjs(state?.expiry).format("YYYY-MM-DD"),
                  facilitator_id: state?.facilitator?.shared_id,
                  department_id: sharedDeptId,
                  commodity_id: state?.commodity?.shared_id,
                  commodity_variety_id: state?.commodity_variety?.shared_id,
                  plot_crop_id: state?.plot_crop?.shared_id,
                  seller_id: state?.seller?.shared_id,
                  buyer_id: state?.buyer?.shared_id,
                  trade_amounts_counter: 0,

                  trade_items: state?.trade_items?.filter(i => !isEmpty(i))
                    ?.length
                    ? {
                        data: state?.trade_items
                          ?.filter(item => !removedTradeItems[item.id])
                          .map(item => {
                            const qcResult = qcForm.values.results.find(
                              result =>
                                result.template.grade.name ===
                                item.variety_grade.name
                            )
                            return {
                              ...(!isHishobPatti &&
                                !isInwardPatti &&
                                !newBookingPatti &&
                                !(newHishobPatti && isStatusGenerateInward) && {
                                  id: item.id,
                                }),
                                ...((newHishobPatti || newBookingPatti || newInwardPatti ) && {
                                  parent_trade_item_id: item.id,
                                  }),
                              result_id: item?.result_id || qcResult?.shared_id,
                              rate: item.rate,
                              daily_rate:
                                item.daily_rate || item.daily_rate === 0
                                  ? Number(item.daily_rate)
                                  : undefined,
                              rate_unit_id: quantityUnit?.shared_id,
                              quantity: item.quantity,
                              quantity_unit_id: quantityUnit?.shared_id,
                              variety_grade_id: item.variety_grade?.shared_id,
                              currency_id: defaultCurrency?.id,
                            }
                          }),
                        on_conflict: {
                          constraint: Trade_Item_Constraint.TradeItemPkey,
                          update_columns: [
                            Trade_Item_Update_Column.VarietyGradeId,
                            Trade_Item_Update_Column.Rate,
                            Trade_Item_Update_Column.DailyRate,
                            Trade_Item_Update_Column.RateUnitId,
                            Trade_Item_Update_Column.Quantity,
                            Trade_Item_Update_Column.QuantityUnitId,
                            Trade_Item_Update_Column.CurrencyId,
                            Trade_Item_Update_Column.ResultId,
                          ],
                        },
                      }
                    : undefined,
                  expenses: state?.expenses?.filter(
                    i => i.seller || i.buyer || i.facilitator
                  )?.length
                    ? {
                        data: state?.expenses?.reduce((prev, expense) => {
                          const commonProperties = {
                            expense_parameter_id: expense.expense_parameter?.id,
                            kind: expense.kind?.id,
                          }
                          if (expense.buyer?.value) {
                            prev.push({
                              ...commonProperties,
                              ...(!isHishobPatti &&
                                !isInwardPatti &&
                                !newBookingPatti &&
                                !(newHishobPatti && isStatusGenerateInward) && {
                                  id: expense.buyer.id,
                                }),
                              paid_by: "buyer",
                              value: expense.buyer.value,
                            })
                          }
                          if (expense.seller?.value) {
                            prev.push({
                              ...commonProperties,
                              ...(!isHishobPatti &&
                                !isInwardPatti &&
                                !newBookingPatti &&
                                !(newHishobPatti && isStatusGenerateInward) && {
                                  id: expense.seller.id,
                                }),
                              paid_by: "seller",
                              value: expense.seller.value,
                            })
                          }
                          if (expense.facilitator?.value) {
                            prev.push({
                              ...commonProperties,
                              ...(!isHishobPatti &&
                                !isInwardPatti &&
                                !newBookingPatti &&
                                !(newHishobPatti && isStatusGenerateInward) && {
                                  id: expense.facilitator.id,
                                }),
                              paid_by: "facilitator",
                              value: expense.facilitator.value,
                            })
                          }
                          return prev
                        }, []),
                        on_conflict: {
                          constraint: Expense_Constraint.ExpensePkey,
                          update_columns: [
                            Expense_Update_Column.ExpenseParameterId,
                            Expense_Update_Column.Kind,
                            Expense_Update_Column.PaidBy,
                            Expense_Update_Column.Value,
                          ],
                        },
                      }
                    : undefined,

                  advance_payments: state?.advance_payment?.length
                    ? {
                        data: state?.advance_payment.map(advance => ({
                          ...(advance?.id && {
                            id: advance.id,
                          }),
                          parameter_id: advance.parameter?.id,
                          amount: advance.amount,
                          bourn_by: advance.bourn_by?.id,
                          user_id:
                            advance.bourn_by?.id === "Farmer"
                              ? state?.seller?.shared_id
                              : advance.bourn_by?.id === "Company"
                              ? state?.buyer?.shared_id
                              : undefined,
                        })),
                        on_conflict: {
                          constraint:
                            Advance_Payment_Constraint.AdvancePaymentPkey,
                          update_columns: [
                            Advance_Payment_Update_Column.Amount,
                            Advance_Payment_Update_Column.BournBy,
                            Advance_Payment_Update_Column.ParameterId,
                          ],
                        },
                      }
                    : undefined,

                  credit_notes: state?.credit_notes?.length
                    ? {
                        data: state?.credit_notes.map(credit => ({
                          ...(!isHishobPatti &&
                            !isInwardPatti &&
                            !newBookingPatti &&
                            !(newHishobPatti && isStatusGenerateInward) && {
                              id: credit.id,
                            }),
                          amount: credit.amount,
                          remarks: credit.remarks,
                        })),
                        on_conflict: {
                          constraint: Credit_Note_Constraint.CreditNotePkey,
                          update_columns: [
                            Credit_Note_Update_Column.Amount,
                            Credit_Note_Update_Column.Remarks,
                          ],
                        },
                      }
                    : undefined,
                },
              })
              if (error || !iData) {
                return toast.custom(
                  <Toast
                    title={"Unable to create the trade request"}
                    intent="danger"
                  />
                )
              }
              if (iData?.insert_trade_one?.id) {
                await incrementTradeCounter({ id: iData.insert_trade_one.id })
                toast.custom(<Toast title={"Trade created successfully!"} />)
                navigate(`/d/trade-requests/${iData?.insert_trade_one?.id}`)
                setOpen(true)
              }
            }}
            leftIcon={<CheckCircle />}
          >
            {state?.id ? "Save" : "Create"}{" "}
            {state?.kind === TradeKinds.HishobPatti || isHishobPatti
              ? "Hishob Patti"
              : state?.kind === TradeKinds.SaudaPavti
              ? "Sauda Pavti"
              : state?.kind === TradeKinds.Booking
              ? "Booking Patti"
              : state?.kind === TradeKinds.StoragePatti
              ? "Storage Patti"
              : null}
          </Button>
        )}
      </footer>
      {id && id !== "new" && (
        <SendConsent
          kind={state.kind}
          buyer={state.buyer}
          seller={state.seller}
          tradeId={id}
          isOpen={isOpen}
          showGenerateSaleAction={
            state?.parent_trade?.kind === "inward" && state?.kind === "final"
          }
          setPackingOpen={() => {
            setTimeout(() => {
              if (pData?.packaging_order.length || 0 > 0) {
                setPackingOpen(true)
              } else {
                navigate(AppRoutes.tradeRequests)
              }
            }, 500)
          }}
          onClose={() => setOpen(false)}
        />
      )}

      <PackingMaterialSaveModal
        pData={pData}
        isOpen={packingOpen}
        onClose={() => {
          setPackingOpen(false)
          navigate(AppRoutes.tradeRequests)
        }}
      />
      {attachmentModal && (
        <AttachmentModal
          form={form}
          isOpen={attachmentModal}
          attachments={form.values?.attachments || []}
          addAttachment={attachment => {
            form.setFieldValue("attachments", attachment)
          }}
          disabled={
            isStatusGenerateSale || (isCompletelyDisabled && !isHishobPatti)
          }
          onClose={() => {
            setAttachmentModal(false)
          }}
        />
      )}
    </>
  )
}

export default TradeRequestDetail
