import { useForm } from "@mantine/form"
import { CheckCircle, Percent, Plus } from "@phosphor-icons/react"
import {
  Breadcrumbs,
  Button,
  Card,
  Flex,
  FormGroup,
  FormInput,
  Group,
  QuerySelector,
  Toast,
} from "@vesatogo/grass-core"
import { DateInput } from "@vesatogo/grass-dates"
import "@vesatogo/grass-dates/index.css"
import { inr } from "@vesatogo/utils"
import dayjs from "dayjs"
import { cloneDeep, debounce, set } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import CreateReceiptSummary from "~/components/CreateReceiptSummary"
import { FormCard } from "~/components/FormCard"
import HowIsItTraded from "~/components/HowIsItTraded"
import { PackingMaterialSaveModal } from "~/components/PackingMaterial/PackingMaterialSaveModal"
import TermsAndConditions from "~/components/TermsAndConditions"
import TradeBuyer from "~/components/TradeBuyer"
import TradeExpenses, { EXPENSE } from "~/components/TradeExpenses"
import WhatIsBeingTraded from "~/components/WhatIsBeingTraded"
import { WhoIsTrading } from "~/components/WhoIsTrading"
import Codenames from "~/constants/Codenames"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import { AppRoutes } from "~/constants/routes"
import {
  ReceiptDetailQuery,
  useAddUpdateReceiptMutation,
  useAddUpdateSharedEntityMutation,
  useAllVehiclesQuery,
  useGetSharedEntityQuery,
  useReceiptDetailQuery,
  useUserPackingQuery,
} from "~/generated/graphql"
import {
  ReceiptState,
  Trade,
  TradeItem,
} from "~/pages/d/trade-requests/receipts/types"
import { SideBarItem } from "~/pages/d/trade-requests/sections/SideBar"
import { expenseAmount, normalizeExpenses } from "~/utils/expenseAmount"
import { useDepartment } from "~/utils/useDepartment"

type SharedEntity = {
  group: SharedEntityGroup
}

export const TRADE_ITEM: Partial<TradeItem> = {
  grade: null,
}
const TRADE: Trade = {
  buyer: null,
  attachments: [] as any,
  items: [TRADE_ITEM],
}

const INITIAL_STATE: ReceiptState = {
  mode: "direct",
  kind: "pro_forma",
  packaging: "raw_material",
  trades: [TRADE],
  start_date: new Date(),
  end_date: null,
  expiry: null,
  expenses: [EXPENSE],
  total_quantity: 0,
  total_amount: 0,
  seller_expense_amount: 0,
  seller_receivable_amount: 0,
  advance_payment: [],
  credit_notes: [],
  advance_amount: 0,
  supplierAdvanceAmount: 0,
}
const ReceiptDetail = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [, upsertSharedEntity] = useAddUpdateSharedEntityMutation()
  const [{ fetching }, addUpdateReceipt] = useAddUpdateReceiptMutation()
  const { id: sharedDeptId } = useDepartment()

  const [{ data: sData }] = useGetSharedEntityQuery({
    variables: {
      group: SharedEntityGroup.KiteCurrency,
      codename: Codenames.KiteDefaultCurrency,
    },
  })

  const defaultCurrency = sData?.setu_trade_shared_entity?.[0]
  const { id } = useParams()

  const form = useForm<ReceiptState>({ initialValues: INITIAL_STATE })
  const { values: state, setValues: setState } = form

  const [isOpen, setOpen] = useState(false)
  const [packingOpen, setPackingOpen] = useState(false)

  const idNum = parseInt(id as any)
  const newfprId = parseInt(searchParams.get("newfpr") || "")

  const [{ data: dData }] = useReceiptDetailQuery({
    variables: { id: idNum },
    pause: !idNum,
    requestPolicy: "network-only",
  })
  const [{ data: pData }] = useUserPackingQuery({
    variables: {
      where: {
        user_id: {
          _eq: form.values?.seller?.shared_id,
        },
      },
    },
  })

  const isDisabled =
    (dData?.receipt_by_pk?.editable_trades?.aggregate?.count &&
      form.values?.id) ||
    dData?.receipt_by_pk?.child_receipts_aggregate?.aggregate?.count
      ? true
      : false

  const isHishobPattiAdded =
    dData?.receipt_by_pk?.hishob_patti_count?.aggregate?.count &&
    dData?.receipt_by_pk?.kind === "booking" &&
    form.values?.id &&
    dData?.receipt_by_pk?.child_receipts_aggregate?.aggregate?.count
      ? true
      : false
  function updateReceiptState(receipt?: ReceiptDetailQuery["receipt_by_pk"]) {
    if (!receipt) return

    const newState = {
      ...receipt,
      id: newfprId ? undefined : receipt.id,
      buyer_tnc: receipt.buyer_tnc,
      seller_tnc: receipt.seller_tnc,
      kind: Boolean(searchParams.get("new")) ? "final" : receipt.kind,
      mode: receipt.mode,
      commodity: {
        ...receipt.commodity?.commodity[0],
        shared_id: receipt.commodity?.id,
      },
      commodity_variety: {
        ...receipt.commodity_variety?.commodity_variety[0],
        shared_id: receipt.commodity_variety?.id,
      },
      vehicle: {
        ...receipt.vehicle,
        shared_id: receipt?.vehicle?.id,
      },
      plot_crop: {
        ...receipt.plot_crop?.plot_crop[0],
        shared_id: receipt.plot_crop?.id,
      },
      facilitator: {
        ...receipt.facilitator?.facilitator[0],
        shared_id: receipt.facilitator?.id,
      },
      seller: {
        ...receipt?.seller?.user[0],
        shared_id: receipt?.seller?.id,
      },
      unit: {
        ...receipt?.firstTrade?.[0]?.trade_items?.[0]?.quantity_unit
          ?.si_unit?.[0],
        shared_id:
          receipt?.firstTrade?.[0]?.trade_items?.[0]?.quantity_unit?.id,
      },
      rate_unit: {
        ...receipt?.firstTrade?.[0]?.trade_items?.[0]?.rate_unit?.si_unit?.[0],
        shared_id: receipt?.firstTrade?.[0]?.trade_items?.[0]?.rate_unit?.id,
      },
      total_amount: receipt.total_amount,
      total_quantity: receipt.total_quantity,
      amount_deducted:
        (receipt.total_amount * receipt.standard_deduction) / 100,
      quantity_deducted:
        (receipt.total_quantity * receipt.standard_deduction) / 100,
      packaging: receipt.packaging as any,
      seller_receivable_amount: receipt.seller_receivable_amount || 0,
      seller_expense_amount: receipt.seller_expense_amount || 0,
      standard_deduction: receipt.standard_deduction,
      start_date: receipt.start_date && new Date(receipt.start_date),
      end_date: receipt.end_date && new Date(receipt.end_date),
      expiry: receipt.expiry && new Date(receipt.expiry),
      parent_receipt_id: newfprId ? newfprId : receipt?.parent_receipt_id,
      trades: receipt.trades?.length
        ? receipt.trades.map(trade => ({
            id: newfprId ? undefined : trade.id,
            attachments: trade.attachments,
            buyer: {
              ...trade.buyer?.user[0],
              shared_id: trade.buyer?.id,
            },
            total_amount: trade.total_amount,
            total_quantity: trade.total_quantity,
            items: trade.trade_items?.length
              ? trade.trade_items?.map(item => ({
                  id: newfprId ? undefined : item.id,
                  quantity: item.quantity,
                  rate: item.rate,
                  total_amount: item.total_amount,
                  parent_trade_item_id: newfprId
                    ? item.id
                    : item.parent_trade_item_id,
                  grade: {
                    ...item.variety_grade?.variety_grade[0],
                    shared_id: item.variety_grade?.id,
                  },
                }))
              : [TRADE_ITEM],
          }))
        : [TRADE],

      expenses: normalizeExpenses(receipt.expenses, newfprId ? true : false),
      advance_payment: receipt.advance_payments?.map(advance => ({
        ...advance,
        bourn_by: { id: advance.bourn_by, name: advance.bourn_by },
      })),
      credit_notes: receipt?.credit_notes,
      expense_amount: receipt.expense_amount,
    }

    setState(newState)
    runComputations(newState)
  }
  const receiptId = dData?.receipt_by_pk?.id

  useEffect(() => {
    if (receiptId) {
      updateReceiptState(dData?.receipt_by_pk)
    }
  }, [receiptId, searchParams.get("new")])

  useEffect(() => {
    if (
      dData &&
      sharedDeptId &&
      dData?.receipt_by_pk?.department.id !== sharedDeptId
    ) {
      navigate(AppRoutes.tradeReceipts)
    }
  }, [sharedDeptId, dData?.receipt_by_pk?.department.reference_id])

  const runComputations = useMemo(
    () =>
      debounce(values => {
        const stateCopy = cloneDeep(values)
        let receiptTotalQty = 0,
          receiptTotalAmount = 0,
          totalExpenseAmount = 0,
          sellerExpenseAmount = 0,
          advanceTotalAmount = 0,
          supplierAdvanceAmount = 0
        for (let i = 0; i < stateCopy.trades.length; i++) {
          const trade = stateCopy.trades[i]
          let tradeTotalQty = 0,
            tradeTotalAmount = 0
          for (let j = 0; j < trade.items.length; j++) {
            const item = trade.items[j]
            let itemTotalAmount = 0
            tradeTotalQty += Number(item.quantity) || 0
            itemTotalAmount +=
              (Number(item.quantity) || 0) * (Number(item.rate) || 0)
            stateCopy.trades[i].items[j].total_amount = itemTotalAmount
            tradeTotalAmount += itemTotalAmount
          }
          stateCopy.trades[i].total_quantity = tradeTotalQty
          stateCopy.trades[i].total_amount = tradeTotalAmount
          receiptTotalQty += tradeTotalQty
          receiptTotalAmount += tradeTotalAmount
        }

        stateCopy.total_quantity = receiptTotalQty
        stateCopy.total_amount = receiptTotalAmount
        stateCopy.amount_deducted =
          stateCopy.total_amount *
          (Number(stateCopy.standard_deduction || 0) / 100)

        stateCopy.quantity_deducted =
          stateCopy.total_quantity *
          (Number(stateCopy.standard_deduction || 0) / 100)

        for (let j = 0; j < stateCopy.expenses.length; j++) {
          const expense = stateCopy.expenses[j]
          const expenseParams = {
            kind: expense?.kind?.id,
            buyerAmount: Number(expense.buyer?.value) || 0,
            sellerAmount: Number(expense.seller?.value) || 0,
            facilitatorAmount: Number(expense.facilitator?.value) || 0,
            finalAmount: stateCopy.total_amount,
            finalQuantity:
              stateCopy.total_quantity - stateCopy.quantity_deducted,
            totalQuantity: stateCopy.total_quantity,
            trades: stateCopy.trades?.length,
          }
          expense.amount = expenseAmount(expenseParams)
          ;(sellerExpenseAmount += expenseAmount({
            ...expenseParams,
            buyerAmount: 0,
          })),
            (totalExpenseAmount += expense.amount)
        }
        for (let j = 0; j < stateCopy.advance_payment.length; j++) {
          const advance = stateCopy.advance_payment[j]
          if(advance.parameter.name === 'Supplier Advance'){
            supplierAdvanceAmount += Number(advance.amount) || 0
          }
          advanceTotalAmount += Number(advance.amount) || 0
        }
         
        stateCopy.supplierAdvanceAmount = supplierAdvanceAmount
        stateCopy.advance_amount = advanceTotalAmount
        stateCopy.expense_amount = totalExpenseAmount
        stateCopy.seller_expense_amount = sellerExpenseAmount
        stateCopy.seller_receivable_amount =
          (stateCopy.total_amount || 0) -
          (stateCopy.amount_deducted || 0) - 
          (supplierAdvanceAmount || 0) - 
          (stateCopy.seller_expense_amount || 0) +
          (parseFloat(stateCopy?.credit_notes?.[0]?.amount) || 0)
        form.setValues(stateCopy)
      }, 500),
    []
  )
  function inputProps(name: string, sharedEntity?: SharedEntity) {
    return {
      ...form.getInputProps(name),
      disabled: isDisabled || isHishobPattiAdded,
      onChange: async (e: any) => {
        const stateCopy = cloneDeep(form.values)
        let facilitator = stateCopy.seller?.companies?.[0]?.company
        if (name === "mode" && e === "facilitator" && facilitator) {
          const { data: facilitatorShared } = await upsertSharedEntity({
            reference_id: facilitator.codename,
            group_name: SharedEntityGroup.KiteCompany,
            name: facilitator.name,
          })
          let shared_id =
            facilitatorShared?.insert_setu_trade_shared_entity_one?.id
          set(stateCopy, "facilitator", { ...facilitator, shared_id })
        } else if (
          (name === "mode" && e === "direct") ||
          (name === "mode" && e === "facilitator" && !facilitator) ||
          (name === "seller" && !e?.companies.length)
        ) {
          set(stateCopy, "facilitator", null)
          set(stateCopy, "mode", "direct")
        }

        if (sharedEntity && e?.codename && e?.name && !e?.shared_id) {
          const { data } = await upsertSharedEntity({
            reference_id: e.codename,
            group_name: sharedEntity.group,
            name: e.name,
          })
          set(stateCopy, name, {
            ...e,
            shared_id: data?.insert_setu_trade_shared_entity_one?.id,
          })
        } else {
          set(stateCopy, name, e?.target ? e.target.value : e)
        }
        runComputations(stateCopy)
        form.setValues(stateCopy)
      },
    }
  }

  function handleSubmit() {
    return form.onSubmit(async values => {
      try {
        const { data, error } = await addUpdateReceipt({
          input: {
            id: values.id as any,
            parent_receipt_id: values.parent_receipt_id as any,
            seller_id: values.seller?.shared_id,
            department_id: sharedDeptId,
            kind: values.kind,
            mode: values.mode,
            vehicle_id: values.vehicle?.shared_id,
            buyer_tnc: values.buyer_tnc,
            seller_tnc: values.seller_tnc,
            facilitator_id: values.facilitator?.shared_id,
            commodity_id: values.commodity?.shared_id,
            commodity_variety_id: values.commodity_variety?.shared_id,
            plot_crop_id: values.plot_crop?.shared_id,
            packaging: values.packaging,
            start_date: values.start_date
              ? dayjs(values.start_date).format("YYYY-MM-DD")
              : null,
            end_date: values.end_date
              ? dayjs(values.end_date).format("YYYY-MM-DD")
              : null,
            expiry: values.expiry
              ? dayjs(values.expiry).format("YYYY-MM-DD")
              : null,
            standard_deduction: values.standard_deduction,
            trades: values.trades?.length
              ? values.trades.map(trade => {
                  return {
                    id: trade.id as any,
                    buyer_id: trade.buyer?.shared_id,
                    attachments: trade.attachments.map(att => att.media.id),
                    items: trade.items?.length
                      ? trade.items.map(item => {
                          return {
                            id: item.id as any,
                            quantity: Number(item.quantity),
                            parent_trade_item_id:
                              item.parent_trade_item_id as any,
                            rate: Number(item.rate),
                            unit_id: values.unit?.shared_id,
                            currency_id: defaultCurrency?.id,
                            variety_grade_id: item.grade?.shared_id,
                          }
                        })
                      : [],
                  }
                })
              : [],
            expenses: values?.expenses?.filter(i => i.seller || i.buyer)?.length
              ? values.expenses.reduce<any[]>((prev, expense) => {
                  const commonProperties = {
                    expense_parameter_id: expense.expense_parameter?.id,
                    kind: expense.kind?.id,
                  }
                  if (expense.buyer?.value) {
                    prev.push({
                      ...commonProperties,
                      id: expense.buyer.id,
                      paid_by: "buyer",
                      value: expense.buyer.value,
                    })
                  }
                  if (expense.seller?.value) {
                    prev.push({
                      ...commonProperties,
                      id: expense.seller.id,
                      paid_by: "seller",
                      value: expense.seller.value,
                    })
                  }
                  return prev
                }, [])
              : undefined,

            advance_payments:
              values?.advance_payment?.length > 0
                ? values?.advance_payment.map(advance => ({
                    id: advance.id,
                    parameter_id: advance.parameter?.id,
                    amount: advance.amount,
                    bourn_by: advance.bourn_by?.id,
                  }))
                : [],

            credit_notes:
              values?.credit_notes?.length > 0 && values?.credit_notes[0]?.amount > 0
                ? values?.credit_notes.map(credit => ({
                    id: credit.id,
                    amount: credit.amount,
                    remarks: credit.remarks,
                  }))
                : [],
          },
        })
        if (data?.add_update_receipt?.receipt.id) {
          toast.custom(<Toast title="Receipt saved successfully" />)
          setOpen(false)
          setTimeout(() => {
            if (pData?.packaging_order.length || 0 > 0) {
              setPackingOpen(true)
            } else {
              navigate(AppRoutes.tradeReceipts)
            }
          }, 500)
        }

        if (error) {
          throw new Error(error.message)
        }
      } catch (error: any) {
        return toast.custom(<Toast title={error?.message} intent="danger" />)
      }
    })
  }

  const finalTotal =
    (form.values.total_amount || 0) - (form.values.amount_deducted || 0)

  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Receipts",
              link: AppRoutes.tradeReceipts,
              isActive: false,
            },
            {
              text: id?.toString() || "New",
              link: AppRoutes.tradeReceiptDetail(id || "new"),
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
      </header>
      <Group className="flex gap-4 px-5 pt-8 pb-16 relative">
        {form.values.parent_receipt_id && (
          <Card className="border flex-[0_0_300px] rounded-lg overflow-hidden self-start">
            <Link
              to={
                AppRoutes?.tradeReceipts + "/" + form.values.parent_receipt_id
              }
              className="flex-1 block"
            >
              <SideBarItem active={false}>
                Parent Trade - # {form.values.parent_receipt_id}
              </SideBarItem>
            </Link>
          </Card>
        )}

        <div className="w-[70%] mx-auto">
          <WhoIsTrading
            form={form}
            inputProps={inputProps}
            isDisabled={isDisabled || isHishobPattiAdded}
            showBuyer={false}
          />
          <HowIsItTraded
            form={form}
            showBookingKinds
            inputProps={inputProps}
            isDisabled={isDisabled || isHishobPattiAdded}
          />
          <WhatIsBeingTraded
            form={form}
            inputProps={inputProps}
            isDisabled={isDisabled || isHishobPattiAdded}
          />
          <FormCard title="When is it traded">
            <div className={"grid grid-cols-4 gap-4"}>
              <FormGroup required label="Start Date of Trade">
                <DateInput {...inputProps("start_date")} />
              </FormGroup>
              <FormGroup label="End Date of Trade">
                <DateInput {...inputProps("end_date")} />
              </FormGroup>
              <FormGroup label="Validity of Trade" required>
                <DateInput {...inputProps("expiry")} />
              </FormGroup>
              <FormGroup label="Vehicle">
                <QuerySelector
                  dataHook={useAllVehiclesQuery}
                  serverSideQuery
                  useWildCardsInQuery={false}
                  variables={{
                    first: 20,
                  }}
                  getOptionLabel={item => item.codename}
                  {...inputProps("vehicle", {
                    group: SharedEntityGroup.StarlingVehicle,
                  })}
                />
              </FormGroup>
            </div>
          </FormCard>
          <FormCard
            childrenClassName={"!p-0"}
            title="Trade Particulars"
            action={
              <Button
                disabled={isDisabled || isHishobPattiAdded}
                onClick={() => form.insertListItem("trades", TRADE)}
                leftIcon={<Plus />}
                size="sm"
              >
                Buyer
              </Button>
            }
          >
            <div className="p-4">
              {form.values.trades?.map((buyer, index) => {
                return (
                  <TradeBuyer
                    isDisabled={isDisabled || isHishobPattiAdded}
                    runComputations={runComputations}
                    inputProps={inputProps}
                    index={index}
                    form={form}
                    defaultCurrency={defaultCurrency}
                    key={buyer.id}
                    items={buyer.items}
                  />
                )
              })}
              <hr className="border-gray-300" />
              <div className="grid grid-cols-4 gap-4 text-base p-4">
                <div>Total</div>
                <div></div>
                <div>
                  {form.values.total_quantity} {form.values.unit?.name || "na"}
                </div>
                <div className="flex justify-between">
                  <span>value:</span>
                  <span>{inr(form.values.total_amount || 0)}</span>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4 text-base p-4">
                <div className="col-span-2">
                  <FormInput
                    inline
                    labelProps={{
                      className: "text-base",
                    }}
                    label="Standard Deduction of Wt"
                    size="sm"
                    type="number"
                    rightElement={<Percent />}
                    {...inputProps("standard_deduction")}
                    min={0}
                    max={100}
                  />
                </div>
                <div className="text-red-500">
                  {form.values.quantity_deducted?.toFixed(2)}{" "}
                  {form.values.unit?.name || "na"}
                </div>
                <div className="flex justify-between">
                  <span></span>
                  <span className="text-red-500">
                    {inr(form.values.amount_deducted || 0)}
                  </span>
                </div>
              </div>
            </div>
            <footer className="grid grid-cols-4 gap-4 text-base p-4 bg-green-100">
              <div className="font-500">Final Total</div>
              <div></div>
              <div>
                {(form.values.total_quantity || 0) -
                  (form.values.quantity_deducted || 0)}{" "}
                {form.values.unit?.name || "na"}
              </div>
              <div className="flex justify-between pr-4">
                <span>value:</span>
                <span>{inr(finalTotal)}</span>
              </div>
            </footer>
          </FormCard>
          <TradeExpenses
            form={form}
            inputProps={inputProps}
            isDisabled={isDisabled || isHishobPattiAdded}
            sharedDeptId={sharedDeptId}
            onExpenseTemplateChange={expenseConfig => {
              const stateCopy = cloneDeep(form.values)
              stateCopy.expenses = normalizeExpenses(
                expenseConfig.expense_configs || []
              )
              runComputations(stateCopy)
              form.setValues(stateCopy)
            }}
            onDeleteExpense={idx => {
              const stateCopy = cloneDeep(form.values)
              stateCopy.expenses.splice(idx, 1)
              runComputations(stateCopy)
              form.setValues(stateCopy)
            }}
            onDeleteAdvancePayment={idx => {
              const stateCopy = cloneDeep(form.values)
              stateCopy.advance_payment.splice(idx, 1)
              form.setValues(stateCopy)
              runComputations(stateCopy)
            }}
          />
          <FormCard
            childrenClassName={"flex flex-col gap-2"}
            title="Seller Payment"
          >
            <Flex className="!justify-between">
              <span className="text-gray-700">Final Total</span>
              <span className="font-600">{inr(finalTotal)}</span>
            </Flex>
            {form.values?.credit_notes[0]?.amount && (
              <Flex className="!justify-between">
                <span className="text-gray-700">Credit Note</span>
                <span className="font-600">
                  {inr(form.values?.credit_notes[0]?.amount)}
                </span>
              </Flex>
            )}

            <Flex className="!justify-between">
              <span className="text-gray-700">Seller Expense</span>
              <span className="font-600 text-red-400">
                {inr(form.values.seller_expense_amount || 0)}
              </span>
            </Flex>
            {form.values?.supplierAdvanceAmount > 0 && (
              <Flex className="!justify-between">
                <span className="text-gray-700">Supplier Advance Payment</span>
                <span className="font-600 text-red-400">
                  {inr(form.values?.supplierAdvanceAmount  || 0)}
                </span>
              </Flex>
            )}

            <hr className="border-dashed" />
            <Flex className="!justify-between">
              <span className="font-500">
                <span className="text-green-400 text-base">
                  Seller Payable Amount
                </span>{" "}
                <span className="text-sm text-gray-600">
                  (i.e. Final total + Credit note - Seller expense - Advance payment (Supplier Advance))
                </span>
              </span>
              <span className="font-600">
                {inr(form.values?.seller_receivable_amount || 0)}
              </span>
            </Flex>
          </FormCard>
          <TermsAndConditions
            isDisabled={isDisabled || isHishobPattiAdded}
            kind={form.values.kind}
            mode={form.values.mode}
            sharedDeptId={sharedDeptId}
            seller_tnc={form.values.seller_tnc}
            buyer_tnc={form.values.buyer_tnc}
            onChange={(path, value) => form.setFieldValue(path, value)}
          />
        </div>
      </Group>
      <footer className="fixed bg-white left-0 bottom-0 w-full p-2 shadow-sm flex justify-end gap-4">
        <Button
          onClick={() => {
            if (!form.values.seller?.id) {
              return toast.custom(
                <Toast
                  intent="danger"
                  title="Please select a farmer before proceeding"
                />
              )
            }

            if (!form.values.trades?.[0]?.items?.[0]?.grade?.id) {
              return toast.custom(
                <Toast
                  intent="danger"
                  title="Please input atleast one trade item before proceeding"
                />
              )
            }

            setOpen(true)
          }}
          loading={fetching}
          leftIcon={<CheckCircle />}
        >
          {isDisabled || isHishobPattiAdded
            ? "View Summary"
            : `${form.values.id ? "Save" : "Create"} ${
                form.values.kind === "final"
                  ? "Hishob Patti"
                  : form.values.kind === "booking"
                  ? "Booking Patti"
                  : "Sauda Pavti"
              }`}
        </Button>
      </footer>
      <CreateReceiptSummary
        isLoading={fetching}
        receipt={form.values}
        primaryActionButtonProps={{
          text: "Save",
          disabled: isDisabled || isHishobPattiAdded,
          leftIcon: <CheckCircle />,
          onClick:
            isDisabled || isHishobPattiAdded
              ? undefined
              : (handleSubmit() as any),
          loading: fetching,
        }}
        isOpen={isOpen}
        onClose={async () => {
          setOpen(false)
        }}
      />
      <PackingMaterialSaveModal
        pData={pData}
        isOpen={packingOpen}
        onClose={() => {
          setPackingOpen(false)
          navigate(AppRoutes.tradeReceipts)
        }}
      />
    </>
  )
}

export default ReceiptDetail
