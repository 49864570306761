import { gql } from "urql"
import { RESULT_BASE_FRAG } from "~/graphql/quality/result.gql"
import { BASE_USER_FRAG } from "./base_user.gql"
import { EXPENSE_FRAG } from "./expense.gql"

export const ADVANCE_PAYMENT_FRAG = /* GraphQL */ `
  fragment AdvancePayFrag on advance_payment {
    id
    parameter {
      id
      name
    }
    amount
    bourn_by
    user_id
    is_verified
  }
`
export const CREDIT_NOTE_FRAG = /* GraphQL */ `
  fragment CreditNoteFrag on credit_note {
    id
    amount
    remarks
    user_id
    is_verified
  }
`

export const TRADE_DETAIL_FRAG = /* GraphQL */ `
  fragment TradeDetailFrag on trade {
    id
    status: current_status_id
    tracking_code
    status_processing
    receipt_id
    department {
      id
      reference_id
    }
    invoice {
      id
    }
    vehicle {
      id
      name
      codename: reference_id
    }
    trades(
      where: { kind: { _in: ["final", "inward", "booking"] } }
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      kind
      start_date
    }
    parent_trade {
      id
      kind
      start_date
    }
    buyer {
      id
      user {
        ...BaseUserFrag
      }
    }
    seller {
      id
      user {
        ...BaseUserFrag
      }
    }
    commodity {
      id
      commodity {
        ...KiteCommodityFrag
      }
    }
    commodity_variety {
      id
      commodity_variety {
        ...KiteCommodityVarietyFrag
      }
    }
    facilitator {
      id
      facilitator {
        id
        name
        codename
      }
    }
    plot_crop {
      id
      plot_crop {
        id
        external_reference_id
        tag
        name
        codename
      }
    }
    attachments {
      id
      media {
        id
        etag
        url
        key
        kind
      }
    }
    standard_deduction
    start_date
    end_date
    expiry
    packaging
    seller_tnc
    buyer_tnc
    seller_verification
    buyer_verification
    mode
    kind
    total_amount
    total_quantity
    deducted_amount
    deducted_quantity
    expense_amount
    trade_items {
      id
      result {
        id
        codename
        result {
          ...ResultBaseFrag
        }
      }
      quantity
      quantity_unit {
        id
        si_unit {
          id
          name
          codename
        }
      }
      variety_grade {
        id
        variety_grade {
          id
          name
          codename
        }
      }
      rate
      total_amount: amount
      daily_rate
      rate_unit {
        id
        si_unit {
          id
          name
          codename
        }
      }
    }
    expenses {
      ...ExpenseFrag
    }
    advance_payments {
      ...AdvancePayFrag
    }
    credit_notes {
      ...CreditNoteFrag
    }
  }
  ${BASE_USER_FRAG}
  ${EXPENSE_FRAG}
  ${RESULT_BASE_FRAG}
  ${ADVANCE_PAYMENT_FRAG}
  ${CREDIT_NOTE_FRAG}
`


export const TRADE_HASHES = /* GraphQL */ `
  query TradeHashes($id: bigint!) {
    trade_by_pk(id: $id) {
      id
      seller_hash
      buyer_hash
    }
  }
`
export const ALL_TRADE_REQUESTS_IDS = /* GraphQL */ `
  query AllTradeRequestsIds(
    $where: trade_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [trade_order_by!]
  ) {
    trade(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id
      current_status_id
      seller {
        id
        user {
          id
          full_name
          number
          external_reference_id
          current_address {
            id
            village
          }
        }
      }
      buyer {
        id
        user {
          id
          full_name
          external_reference_id
        }
      }
      standard_deduction
      total_amount
      total_quantity
      expense_amount
      item_units: trade_items(limit: 1) {
        id
        quantity_unit {
          id
          name
          reference_id
        }
        rate_unit {
          id
          name
          reference_id
        }
      }
      trade_items(order_by: { variety_grade: { name: asc_nulls_last } }) {
        id
        rate
        variety_grade {
          id
          name
        }
        quantity
        amount
      }
    }
  }
`

export const ALL_TRADE_REQUESTS = /* GraphQL */ `
  query AllTradeRequests(
    $where: trade_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [trade_order_by!]
  ) {
    trade(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id
      status_processing
      receipt_id
      invoice {
        id
        current_status_id
      }
      tracking_code
      created_at
      kind
      seller_verification
      buyer_verification
      total_amount
      total_quantity
      expense_amount
      standard_deduction
      seller {
        id
        user {
          id
          full_name
          number
          external_reference_id
          current_address {
            id
            village
          }
        }
      }
      buyer {
        id
        user {
          id
          full_name
          external_reference_id
        }
      }

      commodity {
        id
        name
      }
      commodity_variety {
        id
        name
      }
      item_units: trade_items(limit: 1) {
        id
        quantity_unit {
          id
          name
          reference_id
        }
        rate_unit {
          id
          name
          reference_id
        }
      }
      trade_items(order_by: { variety_grade: { name: asc_nulls_last } }) {
        id
        rate
        variety_grade {
          id
          name
        }
        quantity
        amount
      }
      trade_items_aggregate {
        aggregate {
          sum {
            quantity
          }
          avg {
            rate
          }
        }
      }
      total_amount
      created_by {
        id
        full_name
        photo {
          id
          url
        }
      }
      current_status_id
      updated_at
    }
    trade_statuses: trade_aggregate(
      where: $where
      distinct_on: [current_status_id]
    ) {
      aggregate {
        count
        min {
          current_status_id
        }
      }
    }
    trade_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const ALL_TRADE_REQUESTS_STATUS = /* GraphQL */ `
  subscription AllTradeRequestsStatus(
    $where: trade_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [trade_order_by!]
  ) {
    trade(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id
      current_status_id
      status_processing
    }
  }
`

export const UPSERT_TRADE = /* GraphQL */ `
  mutation UpsertTrade($input: trade_insert_input!) {
    insert_trade_one(
      object: $input
      on_conflict: {
        constraint: trade_pkey
        update_columns: [
          buyer_id
          seller_id
          trade_amounts_counter
          commodity_id
          commodity_variety_id
          plot_crop_id
          packaging
          kind
          mode
          facilitator_id
          standard_deduction
          start_date
          end_date
          expiry
          seller_tnc
          buyer_tnc
          department_id
          vehicle_id
        ]
      }
    ) {
      ...TradeDetailFrag
    }
  }
  ${TRADE_DETAIL_FRAG}
`

export const BROADCAST_CONSENT = /* GraphQL */ `
  mutation BroadcastConsent(
    $trade_id: ID!
    $send_to_buyer: Boolean
    $send_to_seller: Boolean
  ) {
    broadcast_consents(
      trade_id: $trade_id
      send_to_buyer: $send_to_buyer
      send_to_seller: $send_to_seller
    ) {
      buyer_status
      seller_status
    }
  }
`

export const TRADE_DETAIL = /* GraphQL */ `
  query TradeDetail($id: bigint!) {
    trade_by_pk(id: $id) {
      ...TradeDetailFrag
    }
  }
  ${BASE_USER_FRAG}
`

// export const DELETE_TRADE_ITEM = gql`
//   mutation DeleteTradeItem($id: bigint!) {
//     delete_trade_item_by_pk(id: $id) {
//       id
//       trade {
//         id
//         trade_items {
//           id
//         }
//       }
//     }
//   }
//   ${TRADE_DETAIL_FRAG}
// `

export const UPDATE_TRADE_ITEM = gql`
  mutation UpdateTrade($id: bigint!, $_set: trade_set_input) {
    update_trade_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...TradeDetailFrag
    }
  }
  ${TRADE_DETAIL_FRAG}
`

export const INCREMENT_TRADE_ITEM = /* GraphQL */ `
  mutation IncrementTradeCounter($id: bigint!) {
    update_trade_by_pk(
      pk_columns: { id: $id }
      _inc: { trade_amounts_counter: 1 }
    ) {
      id
      trade_amounts_counter
    }
  }
`

export const GET_FINAL_TRADE_LIST = gql`
  query FinalTradeList($parentId: bigint) {
    trade(
      where: {
        parent_trade_id: { _eq: $parentId }
        kind: { _in: ["final", "inward", "booking"] }
      }
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      kind
      parent_trade {
        id
        kind
        start_date
      }
      start_date
    }
  }
`

export const UPDATE_TRADE = /* GraphQL */ `
  mutation BulkUpdateTrade($where: trade_bool_exp!, $set: trade_set_input!) {
    update_trade(where: $where, _set: $set) {
      returning {
        id
        current_status_id
        status_processing
      }
      affected_rows
    }
  }
`
export const BOOKING_TRADE_SUMMARY = /* GraphQL */ `
  query BookingTradeSummary($id: Int!) {
    trade_grade_quantity_summary_report(trade_id: $id) {
      more_trade
      grade_summary {
        variety_grade_id
        remaining_quantity
        booked_quantity
        variety_grade_name
      }
    }
  }
`

export const EXPENSE_CONFIG_VERSIONS = /* GraphQL */ `
  query ExpenseConfigVersions($department: ID, $kind: String!, $mode: String!) {
    expense_config_versions(department: $department, kind: $kind, mode: $mode) {
      version_id
      name
      kind
      mode
    }
  }
`
export const ADVANCE_PAYMENT_PARAMETERS = /* GraphQL */ `
  query AdancedPaymentParameters {
    advance_payment_parameters {
      id
      name
    }
  }
`

export const EXPENSE_CONFIGS = /* GraphQL */ `
  query ExpenseConfigs(
    $version: ID
    $department: ID!
    $kind: String!
    $mode: String!
  ) {
    expense_configs(
      version: $version
      department: $department
      kind: $kind
      mode: $mode
    ) {
      id
      paid_by
      expense_parameter {
        id
        name
      }
      kind
      value
    }
  }
`

export const TRADE_SUMMARY = /* GraphQL */ `
  query GradeQuantitySummary($id: bigint, $grade: String) {
    booked_quantity: trade_item_aggregate(
      where: {
        trade: { parent_trade_id: { _eq: $id } }
        variety_grade: { codename: { _eq: $grade } }
      }
    ) {
      aggregate {
        sum {
          quantity
        }
      }
    }
    total_quantity: trade_item_aggregate(where: { trade_id: { _eq: 1182 } }) {
      aggregate {
        sum {
          quantity
        }
      }
    }
  }
`
