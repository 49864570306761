import { CaretDown } from "@phosphor-icons/react"
import { Button, Menu, MenuItem, Popover, Toast } from "@vesatogo/grass-core"
import toast from "react-hot-toast"
import { APIRoutes } from "~/constants/routes"
import { useReceiptSelectorList } from "~/store/receipts-selector-store"
const ReceiptActions = () => {
  const { selected } = useReceiptSelectorList()
  const shareViaWhatsApp = async () => {
    toast.custom(<Toast title="Message will be sent!" />)
    Object.values(selected)?.map(async receipt => {
      const id = receipt?.id
      const url = `https://${
        import.meta.env.VITE_REST_URL
      }/${APIRoutes.downloadTradeReceipt(id)}`

      const token = localStorage.getItem("token")
      const commodity_name = receipt?.commodity?.name
      const name = receipt?.seller?.user?.[0]?.full_name
      const total_amount = receipt?.total_amount
      const total_quantity = receipt?.total_quantity
      const qty_unit = "kg"
      const number = receipt?.seller?.user?.[0]?.number
      const { url: responseUrl } = await fetch(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      if (responseUrl) {
        const substring = responseUrl.split("trade/")[1]
        const body = {
          to: number,
          contentSid: "HXa6729131a5386d0b8148394aff906d5b",
          statusCallback: `https://${
            import.meta.env.VITE_REST_URL
          }/rest/trade/status-callback/?receipt_id=${id}`,
          contentVariables: {
            name: name,
            commodity_name: commodity_name,
            total_qty: String(total_quantity),
            total_amount: String(total_amount),
            qty_unit: qty_unit,
            link: substring.replace("/", ""),
          },
        }
        const data = await fetch(import.meta.env.VITE_WHATSAPP_URL, {
          headers: {
            "Content-Type": "application/json",
            token: import.meta.env.VITE_WHATSAPP_API_TOKEN,
          },
          method: "POST",
          body: JSON.stringify(body),
        })
      }
    })
  }
  return (
    <Popover
      minimal
      arrow={false}
      className="p-0"
      trigger="mouseenter"
      interactive
      animation="fade"
      theme="light-border"
      placement="bottom"
      content={
        <Menu className="max-h-52">
          <MenuItem onClick={shareViaWhatsApp}>Share via WhatsApp</MenuItem>
        </Menu>
      }
    >
      <Button
        className="!bg-gray-300"
        intent="none"
        leftIcon={<CaretDown weight="fill" />}
      >
        Action
      </Button>
    </Popover>
  )
}

export default ReceiptActions
